import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from '../index.module.less';
import { getEnvVars } from '@/environment';
import DefaultImg from '@/image/news-default-img.png';
import { MessageResponse } from '@/services/MesageAPI/MessageService';
import { getCurrentCompany } from '@/store/app';
import { useAppSelector } from '@/store/hooks';

interface IntroductionNewsProps {
  news: MessageResponse;
}

const { apiUrl } = getEnvVars();
export const IntroductionNews = ({ news }: IntroductionNewsProps) => {
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(DefaultImg);

  const company = useAppSelector(getCurrentCompany());

  useEffect(() => {
    if (!news?.attachmentLinkReadDTOs?.length || !company) {
      setImgSrc('');
      return;
    }
    const drawingSrc = `${apiUrl}/Document/downloadFile/${news.attachmentLinkReadDTOs[0].drawingId}?companyId=${company.id}`;
    setImgSrc(drawingSrc);
  }, [news, company]);

  const onImageError = () => {
    setImgSrc('');
    return true;
  };

  const viewDetail = () => {
    navigate(`/dashboard/news/${news?.id}`);
  };

  return (
    <div className={styles.introductionNews}>
      <div className={styles.imgContainer}>
        <img src={imgSrc || DefaultImg} alt={news?.subject} onError={onImageError} />
      </div>
      <div className={styles.inforWrapper}>
        <div
          className={styles.desc}
          dangerouslySetInnerHTML={{
            __html: news.content || '',
          }}
        ></div>
        <div>
          <Button type="primary" size="large" onClick={viewDetail}>
            Xem thêm
          </Button>
        </div>
      </div>
    </div>
  );
};
