import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { defaultPagingParams, IEmployeeFee, IFeeTableResult } from '@/common/define';
import {
  DanhSachUserResponse,
  EmployeePoint,
  EmployeeResponse,
  EmployeesPagingResponse,
  rankData,
} from '@/services/EmployeeService';
import { RequestOptions } from '@/services/types';

interface EmployeeState {
  DanhSachUser: DanhSachUserResponse[];
  employees?: EmployeesPagingResponse;
  selectedEmployee?: EmployeeResponse;
  selectedEmployeeDetails?: any;
  queryParams: any;
  queryFeeParams: any;
  FeeTableEmployee?: IEmployeeFee[];
  rankData: rankData[];
  rankDataByID: rankData[];
  getEmployeeId: EmployeePoint[];
  updateEmployee: EmployeePoint[];
}

const initialState: EmployeeState = {
  updateEmployee: [],
  getEmployeeId: [],
  rankDataByID: [],
  rankData: [],
  DanhSachUser: [],
  queryParams: defaultPagingParams,
  queryFeeParams: defaultPagingParams,
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setDanhSachUser: (state, action: PayloadAction<DanhSachUserResponse[]>) => {
      state.DanhSachUser = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setQueryFeeParams: (state, action) => {
      state.queryFeeParams = action.payload;
    },
    setFeeTableEmployee: (state, action) => {
      state.FeeTableEmployee = action.payload;
    },
    getDanhSachUserRequest: (state, action: PayloadAction<{ options: RequestOptions }>) => {},
    getEmployeesRequest: (state, action) => {},
    getFeeTableEmployeeRequest: (state, action) => {},
    createFeeTableEmployeeRequest: (state, action) => {},
    updateFeeTableEmployeeRequest: (state, action) => {},

    //[#20938][hoang_nm][25/11/2024] thêm slice update theo tháng
    updateFeeEmployeeByMonthRequest: (state, action) => {},
    
    createEmployeeRequest: (state, action) => {},
    updateEmployeeRequest: (state, action) => {},
    removeEmployeeRequest: (state, action) => {},
    getRanksRequest: (state, action) => {},
    getRanksSuccess: (state, action) => {
      ('');
      state.rankData = action.payload;
    },
    getRankByIdRequest: (state, action) => {},
    getRankByIdSuccess: (state, action) => {
      state.rankDataByID = action.payload;
    },
    updategetByEmployeeIdRequest: (state, action) => {},
    updategetByEmployeeIdSuccess: (state, action) => {
      state.getEmployeeId = action.payload;
    },
    updateEmployeeIdRequest: (state, action) => {},
    updateEmployeeIdSuccess: (state, action) => {
      state.updateEmployee = action.payload;
    },
  },
});

export const employeeActions = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;
