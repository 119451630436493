import React, { useState } from 'react';

import { DownloadOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, ButtonProps, DatePicker, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import './TabHeaderDiary.css';

interface TabHeaderDiaryProps {
  onDownload: () => void;
  onSelectDate: (dates: [Dayjs | null, Dayjs | null] | null) => void;
  onEllipOutLine?: () => void;
  onAddTransfer?: () => void;
  text?: string;
  addButtonProps?: ButtonProps;
}

const TabHeaderDiary: React.FC<TabHeaderDiaryProps> = ({
  onSelectDate,
  onDownload,
  onEllipOutLine,
  onAddTransfer,
  text,
  addButtonProps,
}) => {
  const [selectedDates, setSelectedDates] = useState<[Dayjs | null, Dayjs | null] | null>([
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ]);

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setSelectedDates(dates);
    onSelectDate(dates || [null, null]);
  };

  return (
    <div className="tab-header-diary-container">
      <Typography.Title level={4}>{text && <span>{text}</span>}</Typography.Title>
      {/* {text && <span className="header-text">{text}</span>} */}
      <div className="tab-header-diary">
        <DatePicker.RangePicker
          defaultValue={selectedDates ?? undefined}
          onChange={handleRangeChange}
          className="date-picker"
        />
        {/* <Button icon={<DownloadOutlined />} onClick={onDownload} type="primary" className="download-button" />
        <Button icon={<EllipsisOutlined />} onClick={onEllipOutLine} type="default" className="ellips-button" /> */}
        {/* <Button
        //[#21080][04/12/2024][hoang_nm] Ẩn button + 
          icon={<PlusOutlined />}
          style={{ display: !addButtonProps?.hidden ? 'flex' : 'none' }}
          onClick={onAddTransfer}
          type="primary"
          className="add-transfer-button"
          {...addButtonProps}
        /> */}
      </div>
    </div>
  );
};

export default TabHeaderDiary;
