import styles from '../index.module.less';

type Props = {
  img: string;
  onClick?: () => void;
  title: string;
};

export const ServiceCard = ({ img, onClick, title }: Props) => {
  return (
    // eslint-disable-next-line
    <div className={styles.serviceCard} onClick={onClick}>
      <div className={styles.serviceImg}>
        <img src={img} alt={title} />
      </div>
      <div className={styles.serviceTitle}>
        <h4 className="">{title}</h4>
      </div>
    </div>
  );
};
