/* eslint-disable import/order */
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Button, Col, Form, InputNumber, Modal, Row, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteOptions, eTypeVatTuMayMoc, PhieuDeNghiMuaHangDTO } from '@/common/define';
import { ChiTietDeNghiMuaHangDTO } from '@/services/AccountingInvoiceService';
import { accountingInvoiceActions, getGiaXuatGanNhat, getNccList, getProducts } from '@/store/accountingInvoice';
import { getCurrentUser, getgetUserIIS } from '@/store/app';
import Utils from '@/utils';
import AutoCompleteCustom from '../AutoCompleteCustom';
import ImportGoods from '../ImportGoods';
import { ProposalData } from '../ProposalHistory';
import styles from './MachineryMaterialsConfirm.module.css';

const { Title } = Typography;

interface MachineryMaterialsFormProps {
  proposal: ProposalData;
  type: eTypeVatTuMayMoc;
  capDuyet: number;
  handleClose: any;
}

let optionsNcc: AutoCompleteOptions[] = [];
const MachineryMaterialsConfirm: React.FC<MachineryMaterialsFormProps> = ({ proposal, capDuyet, handleClose }) => {
  const userIIS = useAppSelector(getgetUserIIS());
  const baoCao = useAppSelector(getGiaXuatGanNhat());
  const data = proposal.chiTietDeNghiMuaHang;
  const user = useAppSelector(getCurrentUser());
  const [chiTietHangHoa, setChiTietHangHoa] = useState<ChiTietDeNghiMuaHangDTO[]>([]);
  const productList = useAppSelector(getProducts());
  const dispatch = useAppDispatch();
  const { t } = useTranslation('material');
  const [importModal, setImportModal] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState(false);
  const value = (proposal.capDuyetHienTai ?? 0) + 1;
  const handleImportButtonClick = () => {
    setImportModal(true);
    console.log(proposal, 'proposal' );
  };

  const handleModalOk = () => {
    setImportModal(false);
  };

  const handleModalCancel = () => {
    setImportModal(false);
  };
  const SplitBySupplier = () => {
    dispatch(accountingInvoiceActions.splitDeNghiMuaHangTheoNhaCungCap({
      params: {
        guid: proposal.guid,
      }
    }))
    handleClose();
  };
  //[22/10] [ngoc_td] them autocomplete cho duyet vat tu, may moc
  const nccList = useAppSelector(getNccList());

  const createOptionsNcc = () => {
    const oName: AutoCompleteOptions[] = [];
    if (nccList && nccList.length) {
      nccList.forEach(ncc => {
        oName.push({
          label: `${ncc.ma_kh} / ${ncc.ten_kh}`,  // Adding both code and name
          value: ncc.ma_kh,
          item: {
            name: ncc.ten_kh,
            code: ncc.ma_kh,
          }
        });
      });
    }

    // Assign these options to be used in renderAutocomplete for NCC fields
    optionsNcc = oName; // Use setOptionsNcc to update the state
  };

  useEffect(() => {
    createOptionsNcc();
  }, []);
  const renderCurrency = (value: number) => {
    return value ? value.toLocaleString('en-US') : '';
  };
const renderAutoComplete = (
  newKey: string,
  options: AutoCompleteOptions[],
  value: string,
  typeHandleSelect: string,
  dropdownStyle?: React.CSSProperties,
  status?: 'error' | 'warning',
) => {
  return (
    <AutoCompleteCustom
      keyElement={newKey} // Đảm bảo mỗi hàng có key riêng
      className={styles.newRow}
      style={{ width: 190 }}
      optionsList={options}
      id={newKey}
      status={status}
      onChange={(key: string, data: string) => {
        // Không cần cập nhật toàn bộ dữ liệu trong `onChange`
      }}
      warning={'Value does not exist'}
      onBlur={(key: string, data: any) => {
        setChiTietHangHoa((prevDataSource) => {
          return prevDataSource.map(item => {
            if (item.guid === newKey) {
              return { ...item, typeHandleSelect: data };
            }
            return item;
          });});
      }}
      onSelect={(id: string, data: any) => {
        handelSelect(id, data, typeHandleSelect, newKey);
      }}
      value={value}
      placeholder={'Nhập nhà cung cấp'}
      dropdownStyle={dropdownStyle}
    />
  );
};
  const handelSelect = (id: string, data: string, type: string, key: string) => {
    const ncc = optionsNcc.find(i => i.item.code === data);
    setChiTietHangHoa((prevDataSource) => {
      return prevDataSource.map(item => {
        if (item.guid === key) { // Sử dụng `guid` hoặc `id` để xác định chính xác dòng cần cập nhật
          switch (type) {
            case 'ma_kh':
              return {
                ...item,
                ma_kh: ncc?.item.code || '', // Cập nhật giá trị riêng cho dòng này
              };
            default:
              return item;
          }
        }
        return item; // Giữ nguyên các dòng không thay đổi
      });
    });
  };
  
  useEffect(() => {
    const updatedData = data.map((item) => {
      const product = productList.find((p) => p.ma_vt === item.ma_vt);
      const report = baoCao?.find((b: { ma_vt: string; }) => b.ma_vt === item.ma_vt)  ;
      const giaKeHoach = report? report.gia_gan_nhat : 0;
      return {
        ...item,
        name: product?.ten_vt || '',
        unit: product?.dvt || '',
        giaKeHoach: giaKeHoach,
      };  
    });
    setChiTietHangHoa(updatedData);
  }, [data, productList, baoCao]);

  //1/11/2024: [ngoc_td] hotfix format number
  const renderEditableCell = (
    row: ChiTietDeNghiMuaHangDTO,
    dataIndex: keyof ChiTietDeNghiMuaHangDTO,
  ) => {
    const handleInputChange = (value: number | null, key: string) => {
      setChiTietHangHoa((prevData) =>
        prevData.map((item) =>
          item.id?.toString() === key
            ? { ...item, [dataIndex]: value ?? 0 }
            : item
        )
      );
    };

    return (
      <InputNumber
        value={row[dataIndex] as number}
        formatter={(value) =>
          value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
        }
        parser={(value) => value ? parseFloat(value.replace(/,/g, '')) : 0}
        onChange={(value) => handleInputChange(value as number | null, row.id?.toString() ?? '')}
        style={{ width: '100%' }}
      />
    );
  };  

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN'); // Use 'vi-VN' for Vietnamese locale
  };
  // [21/10/2024][#20538][phuong_td] Cố định một số trường dữ liệu trên table
  const columns: ColumnType<(typeof chiTietHangHoa)[0]>[] = [
    {
      title: <span>{t('Material code')}</span>,
      dataIndex: 'ma_vt',
      key: 'ma_vt',
      width: 150,
      align: 'center',
      fixed : 'left',
    },
    {
      title: <span>{t('Material name')}</span>,
      dataIndex: 'name',
      key: 'name',
      width: 120,
      align: 'center',
      fixed: 'left',
    },
    {
      title: <span>{t('Unit')}</span>,
      dataIndex: 'unit',
      key: 'unit',
      width: 93,
      align: 'center',
    },
    {
      title: <span>{t('This time proposal')}</span>,
      dataIndex: 'so_luong_yeu_cau',
      key: 'so_luong_yeu_cau',
      width: 120,
      align: 'center',
    },
    {
      title: <span>{t('Approved by')}</span>,
      dataIndex: 'NguoiDuyet1',
      key: 'NguoiDuyet1',
      width: 136,
      align: 'center',
      render: (text, record) => user.Firstname, // Assign userIIS[0].un to NguoiDuyet1
    },
    {
      title: <span>NCC được duyệt</span>,
      dataIndex: 'ma_kh',
      key: 'ma_kh',
      width: 197,
      render: (text, record) => {
        let data = text;
        if (typeof text !== 'string') {
          data = text.props.value
        }
        return  renderAutoComplete(record.guid? record.guid : '', optionsNcc, data, 'ma_kh', { width: 400 });
      },
 
      align: 'center',
    },
    {
      title: <span>{t('Approved quantity')}</span>,
      dataIndex: 'so_luong_nhap1',
      key: 'so_luong_nhap1',
      width: 197,
      render: (text, record) => renderEditableCell(record, 'so_luong_nhap1'),
      align: 'center',
    },
    {
      title: <span>Giá kế hoạch</span>,
      dataIndex: 'giaKeHoach',
      key: 'giaKeHoach',
      width: 130,
      align: 'center',
      render: (text) => Number(text).toLocaleString('en-US') || 0,
    },
    {
      title: <span>{t('Approved price')}</span>,
      dataIndex: 'gia',
      key: 'gia',
      width: 157,
      render: (text, record) => renderEditableCell(record, 'gia'), 
      align: 'center',
    },
    {
      title: <span>{t('Into money')}</span>,
      dataIndex: 'tien',
      key: 'tien',
      width: 124,
      align: 'center',
      render: (text, record) => {
        const soLuongNhap = record.so_luong_nhap1 || 0;
        const gia = record.gia || 0;
        const thanhTien = soLuongNhap * gia;

        // Format thành tiền với dấu phẩy ngăn cách hàng nghìn
        return thanhTien.toLocaleString('en-US');
      },
    },

    {
      title: <span className={styles.tableHeader}>{t('Price')} 1</span>,
      dataIndex: 'gia1',
      key: 'gia1',
      width: 100,
      render: (text, record) => renderCurrency(text),
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 1</span>,
      dataIndex: 'nhaCungCap1',
      key: 'nhaCungCap1',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    }, {
      title: <span className={styles.tableHeader}>{t('Price')} 2</span>,
      dataIndex: 'gia2',
      key: 'gia2',
      width: 100,
      render: (text, record) => renderCurrency(text),
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 2</span>,
      dataIndex: 'nhaCungCap2',
      key: 'nhaCungCap2',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    }, {
      title: <span className={styles.tableHeader}>{t('Price')} 3</span>,
      dataIndex: 'gia3',
      key: 'gia3',
      width: 100,
      render: (text, record) => renderCurrency(text),
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 3</span>,
      dataIndex: 'nhaCungCap3',
      key: 'nhaCungCap3',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
  ];
  const confirmProposal = () => {
    // Destructure to remove color from proposal
    const { color, ...proposalWithoutColor } = proposal;
    let alertBoolean = false;
    // Remove 'name' and 'unit' properties from chiTietHangHoa
    const updatedChiTietHangHoa1 = chiTietHangHoa.map(item => {
      const { name, unit, key, ...rest } = item; // Destructure to remove name
      return rest; // Return the rest of the properties
    });
    
    updatedChiTietHangHoa1.forEach(item => {
      if (item.giaKeHoach === 0 || item.giaKeHoach === null) {
        alertBoolean = false;
      } else if (item.giaKeHoach < (item.gia ?? 0)) {
        alertBoolean = true;
      }
    });
  
    const updatedChiTietHangHoa = chiTietHangHoa.map(item => {
      const { name, unit, key, giaKeHoach, ...rest } = item; // Destructure to remove name
      return rest; // Return the rest of the properties
    });
    // Initialize a new variable with the proposal object
    const updatedProposal = {
      ...proposalWithoutColor,
      chiTietHangHoa: updatedChiTietHangHoa,
      chiTietDeNghiMuaHang: updatedChiTietHangHoa,
      hoaDonVAT: [],
      list_of_extensions: []
    };

    // Assign the user based on the value of capDuyet
    if (userIIS && userIIS.length > 0) {
      switch (capDuyet) {
        case 0:
          updatedProposal.nguoiDuyet1 = userIIS[0].un; // Assign user to nguoiDuyet1
          break;
        case 1:
          updatedProposal.nguoiDuyet2 = userIIS[0].un; // Assign user to nguoiDuyet2
          break;
        case 2:
          updatedProposal.nguoiDuyet3 = userIIS[0].un; // Assign user to nguoiDuyet3
          break;
        case 3:
          updatedProposal.nguoiDuyet4 = userIIS[0].un; // Assign user to nguoiDuyet4
          break;
        case 4:
          updatedProposal.nguoiDuyet5 = userIIS[0].un; // Assign user to nguoiDuyet5
          break;
        default:
          console.warn('Unknown capDuyet level');
          break;
      }
    } else {
      console.warn('userIIS is undefined or empty');
    }
    if (alertBoolean) {
      setShowWarning(true);
    } else {
      dispatch(accountingInvoiceActions.ConfirmProposalForm({
        data: updatedProposal as unknown as PhieuDeNghiMuaHangDTO,
        params: {}
      }));
      handleClose();
    }
  };
    return (
    <div>
      <div>
        <Row>
          <Col>
            <Title level={3}>Duyệt đề xuất vật tư</Title>
          </Col>
        </Row>
        <Form
          initialValues={{
            code: proposal.id,
            category: proposal.ma_ct,
          }}
          className={styles.formLayout}
        >
          <Row gutter={16} style={{ width: '120%' }}>
            <Col span={6} className={styles.formItemCol}>
              <Form.Item label={`Cấp duyệt hiện tại`} name="code" className={styles.formItem}>
                <div>{proposal.capDuyetHienTai === 5 ? "Nhập kho" : proposal.capDuyetHienTai}</div>
              </Form.Item>
            </Col>
            <Col span={6} className={styles.formItemCol}>
              <Form.Item label={t('Date of creation of ticket')} className={styles.formItem}>
                <div>{formatDate(proposal.createDate)}</div>
              </Form.Item>
            </Col> 
          </Row>
        </Form>

        <Table
          dataSource={chiTietHangHoa}
          columns={columns}
          rowHoverable={false}
          pagination={false}
          scroll={{ x: 'max-content', y: '50vh' }}
        />
      </div>

      <div id="div2" className={styles.container}>
        {/* Show the "Import to warehouse" button only if capDuyet >= 3 */}

        {proposal.capDuyetHienTai === 5 ? (
          <div>
            <Button type="primary" onClick={handleImportButtonClick} style={{ marginRight: 10 }}>
              {t('Import to warehouse')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                SplitBySupplier();
              }}
            >
              {t('Split by supplier')}
            </Button>
          </div>
        ) : (
          <div></div>
        )}
        {  // [15/10/2024][#20413][ngoc_td]  ẩn button lấy giá & ncc ở màn hình duyệt
        }
        {/* <div>
          <Button
            type="primary"

            style={{ marginRight: 10 }}
            onClick={() => {
              GetTheLowestSupplierPrice();
            }}
          >
            {t('Get the lowest supplier price')}
          </Button>
          <Button
            type="primary"
            // className={styles.button}
            style={{ marginRight: 10 }}
            onClick={() => {
              GetTheNearestSupplierPrice();
            }}
          >
            {t('Get the nearest supplier price')}
          </Button>
        </div> */}
        <div className={styles.buttonNhapkho}>
          <Button
            type="primary"
            disabled={userIIS && userIIS[0] ? userIIS[0].capDuyetChi !== value : true}
            onClick={confirmProposal}
          >
            Duyệt đề xuất
          </Button>
        </div>
      </div>
      <div style={{ textAlign: 'right', width: '100%' }}>
        {showWarning && (<span style={{color: 'red'}}>Giá duyệt không được cao hơn giá kế hoạch!</span>)}
      </div>
      <Modal open={importModal} onOk={handleModalOk} onCancel={handleModalCancel} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }} footer={null} width={1250} style={{ top: 20 }}>
        <div style={{ height: '100%' }}>
          <ImportGoods proposal={proposal} handleClose={handleModalCancel} />
        </div>
      </Modal>
    </div>
  );

};

export default MachineryMaterialsConfirm;
