import React, { useState, useEffect } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import ContractsTableFP from '../components/ContractsTable/ContractsTableFP';
import { EFinancialPlan } from '@/common/define';
import { documentActions, getDocumentQueryParams, getFolderRootId, getPathDocument } from '@/store/documents';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { projectActions } from '@/store/project';

export const AdvancePlan: React.FC = () => {
  const { t } = useTranslation('subcontractor');
  const [activeKey, setActiveKey] = useState<string>(EFinancialPlan.KeHoachTamUng12);
  const [searchStr, setSearchStr] = useState<string>('');
  const folderRootId = useAppSelector(getFolderRootId());
  const [timer, setTimer] = useState<any>(null);
  const params = useAppSelector(getDocumentQueryParams());
  const path = useAppSelector(getPathDocument());

  const dispatch = useAppDispatch();

  // [#20959][dung_lt][26/11/2024] đặt folderRootId khi đang undefinded
  useEffect(() => {
    if (folderRootId === undefined || folderRootId === null) {
      dispatch(projectActions.getFolderRootId({ projectId: -1, isGetId: true }));
      dispatch(projectActions.getFolderRootIdOutProject({ projectId: -1, isGetId: true }));
    }
  }, [folderRootId])
  // [#20959][dung_lt][26/11/2024] tìm kiếm
  const onSearchChange = (evt: any) => {
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const newParams = { ...params, page: 1, search };
      if (!path?.length && folderRootId) {
        dispatch(documentActions.getLabelRequest({ documentId: folderRootId, params: newParams }));
      } else {
        const lastPath = path[(path?.length || 1) - 1];
        if (lastPath) {
          dispatch(documentActions.getLabelRequest({ documentId: lastPath.id, params: newParams }));
        }
      }
    }, 500);
    setTimer(timeoutId);
  };
  // [#20959][dung_lt][26/11/2024] nhập tìm kiếm
  const getTabBarExtraContent = () => {

    return (
      <Space style={{ marginRight: '120px' }}>
        <Input
          value={searchStr}
          onChange={onSearchChange}
          allowClear
          placeholder="Tìm kiếm"
          suffix={searchStr ? null : <SearchOutlined />}
          style={{ width: 255 }}
        />
      </Space>
    )
      
  };
  return (
    <div style={{ padding: '0 5px' }}>
      <Tabs
        defaultActiveKey={EFinancialPlan.KeHoachTamUng12}
        activeKey={activeKey}
        onChange={key => setActiveKey(key)}
        tabBarExtraContent={getTabBarExtraContent()}
      >
        <Tabs.TabPane key={EFinancialPlan.KeHoachTamUng12} tab={t("Advance plan for the 12th")}>
          {<ContractsTableFP typeEFinancialPlan={activeKey} policies={{
            create: ['KeHoachTaiChinh.TamUng.Create'],
            delete: ['KeHoachTaiChinh.TamUng.Delete'],
          }} />}
        </Tabs.TabPane>

        <Tabs.TabPane key={EFinancialPlan.KeHoachTamUng27} tab={t("Advance plan for the 27th")}>
          {<ContractsTableFP typeEFinancialPlan={activeKey} policies={{
            create: ['KeHoachTaiChinh.TamUng.Create'],
            delete: ['KeHoachTaiChinh.TamUng.Delete'],
          }}  />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default AdvancePlan;
