/* eslint-disable import/order */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { eStatusRequest, IBaoXuatNhapTonData, PhieuDeNghiMuaHangDTO } from '@/common/define';
import { ImportGood } from '@/pages/MachineryMaterials/components/ImportGoods/ImportGoods';
import { Customer } from '@/pages/MachineryMaterials/components/NewMachineryMaterialList/addNcc/addNcc';
import { ProposalData } from '@/pages/MachineryMaterials/components/ProposalHistory';
import {
  ChiTietDeNghiMuaHangDTO,
  CostDataCreate,
  DanhSachBoPhanDTO,
  DataType,
  GetTonKhoDTO,
  IAttachmentLinks,
  IBaoCaoXuatNhapTonDTO,
  MoneyTypeDTO,
  PhieuDieuChuyenDTO,
  PhieuNhapXuatKhoDTO,
  ProductDTO,
  ProductUnitDTO,
  ProposalFormDTO,
  ThietBiDTO,
  TonKhoDTO,
  uploadFileCPPS,
  WareHouseDTO,
} from '@/services/AccountingInvoiceService';

interface AccountingInvoiceState {
  products: ProductDTO[];
  wareHouses: WareHouseDTO[];
  productUnits: ProductUnitDTO[];
  machineries: ThietBiDTO[];
  moneyTypes: MoneyTypeDTO[];
  danhSachDuyetChi: PhieuNhapXuatKhoDTO[];
  danhSachDuyetMuaHang: ProposalData[];
  Tonkho: TonKhoDTO[];
  KLdinhmuc: TonKhoDTO[];
  TonKhoTheoNgay: TonKhoDTO[];
  proposalForms: PhieuDeNghiMuaHangDTO[];
  ChiTietHangHoa: ChiTietDeNghiMuaHangDTO[];
  proposalFormSelected?: PhieuDeNghiMuaHangDTO;
  DuyetData?: PhieuDeNghiMuaHangDTO;
  query_danhSachDuyetChi?: any;
  query_danhSachDuyetMuaHang?: any;
  cap_Duyet: any;
  dateRanges: { startDate: string | null; endDate: string | null } | null;
  dateTransfers: { startDate: string | null; endDate: string | null } | null;
  statusRequest?: { api: string; status: eStatusRequest };
  phieuChuyenVatTu: PhieuDieuChuyenDTO[];
  Dieuchuyenvattu: any;
  clearData?: string;
  DanhSachBoPhan?: DanhSachBoPhanDTO[];
  baoCaoXuatNhapTon?: IBaoXuatNhapTonData[];
  priceAndNcc: any[];
  customers: Customer[];
  AdditionalCosts: any[];
  createAdditionalCostData: CostDataCreate[];
  dataAttachmentLinks: IAttachmentLinks[]; // chua thong tin IAttachmentLinks, thông tin ImageUrl la chinh
  dataImage?: FormData;
  createFileCPPS: uploadFileCPPS[];
  createAdditionalCostDataLast: {
    dataCreateLast: any | null;
  };
  baoCaoDanhThuChiPhi?: any | null;
  createImage: any;
  GiaXuatGanNhat: any;
  danhSachNhapKho: any[];
}

const initialState: AccountingInvoiceState = {
  createAdditionalCostDataLast: {
    dataCreateLast: null,
  },
  createImage: undefined,
  createFileCPPS: [],
  dataImage: undefined,
  products: [],
  wareHouses: [],
  productUnits: [],
  machineries: [],
  moneyTypes: [],
  phieuChuyenVatTu: [],
  Dieuchuyenvattu: {},
  danhSachDuyetChi: [],
  danhSachDuyetMuaHang: [],
  Tonkho: [],
  KLdinhmuc: [],
  TonKhoTheoNgay: [],
  query_danhSachDuyetChi: undefined,
  query_danhSachDuyetMuaHang: undefined,
  cap_Duyet: 2,
  proposalForms: [],
  dateRanges: { startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'), endDate: dayjs().format('YYYY-MM-DD') },
  dateTransfers: { startDate: dayjs().startOf('month').format('YYYY-MM-DD'), endDate: dayjs().endOf('month').format('YYYY-MM-DD') },
  ChiTietHangHoa: [],
  DuyetData: undefined,
  priceAndNcc: [],
  customers: [],
  AdditionalCosts: [],
  createAdditionalCostData: [],
  dataAttachmentLinks: [],
  GiaXuatGanNhat: undefined,
  danhSachNhapKho: [],
};
const accountingInvoiceSlice = createSlice({
  name: 'accountingInvoice',
  initialState,
  reducers: {
    // Set
    CreatePhieuDieuChuyen: (state: AccountingInvoiceState, action: PayloadAction<{ data: PhieuDieuChuyenDTO }>) => {},
    CreatePhieuNhapKho: (state: AccountingInvoiceState, action: PayloadAction<{ data: ImportGood }>) => {},
    CreatePhieuXuatKho: (state: AccountingInvoiceState, action: PayloadAction<{ data: PhieuDieuChuyenDTO }>) => {},
    CreatePhieuNhapKhodc: (state: AccountingInvoiceState, action: PayloadAction<{ data: PhieuDieuChuyenDTO }>) => {},
    CreatePhieuDieuChuyenSuccess: (state: AccountingInvoiceState, action) => {
      state.phieuChuyenVatTu = action.payload;
    },
    GetDieuChuyenVatTu: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ madvcs: string; tu_ngay: string; den_ngay: string; ma_kho: string }>,
    ) => {},
    setDieuchuyenvattu: (state: AccountingInvoiceState, action: any) => {
      state.Dieuchuyenvattu = action.payload;
    },
    setClearData: (state: AccountingInvoiceState, action: PayloadAction<string>) => {
      state.clearData = action.payload;
    },
    SetProducts: (state: AccountingInvoiceState, action: any) => {
      state.products = action.payload;
    },
    // [19/10/2024][#20433][ngoc_td] add redux lấy setCustomers
    setCustomers: (state: AccountingInvoiceState, action: any) => {
      state.customers = action.payload;
    },
    SetDanhSachBoPhan: (state: AccountingInvoiceState, action: any) => {
      state.DanhSachBoPhan = action.payload;
    },
    SetWareHouse: (state: AccountingInvoiceState, action: any) => {
      state.wareHouses = action.payload;
    },
    setProductUnits: (state: AccountingInvoiceState, action: any) => {
      state.productUnits = action.payload;
    },
    setMachineries: (state: AccountingInvoiceState, action: any) => {
      state.machineries = action.payload;
    },
    setMoneyTypes: (state: AccountingInvoiceState, action: any) => {
      state.moneyTypes = action.payload;
    },
    setDanhSachDuyetChi: (state: AccountingInvoiceState, action: any) => {
      state.danhSachDuyetChi = action.payload;
    },
    setDanhSachDuyetMuaHang: (state: AccountingInvoiceState, action: any) => {
      state.danhSachDuyetMuaHang = action.payload;
    },
    setDuyetMuaHang: (state: AccountingInvoiceState, action: any) => {
      state.DuyetData = action.payload;
    },
    setTonkho: (state: AccountingInvoiceState, action: any) => {
      state.Tonkho = action.payload;
    },
    setKLdinhmuc: (state: AccountingInvoiceState, action: any) => {
      state.KLdinhmuc = action.payload;
    },
    setTonKhoTheoNgay: (state: AccountingInvoiceState, action: any) => {
      state.TonKhoTheoNgay = action.payload;
    },
    setProposalForms: (state: AccountingInvoiceState, action: any) => {
      state.proposalForms = action.payload;
    },
    setChiTietHangHoa: (state: AccountingInvoiceState, action: any) => {
      state.ChiTietHangHoa = action.payload;
    },
    setProposalFormSelected: (state: AccountingInvoiceState, action: any) => {
      state.proposalFormSelected = action.payload;
    },
    // [15/10/2024][#20413][ngoc_td] add redux lấy giá & ncc
    setPriceAndNcc: (state: AccountingInvoiceState, action: any) => {
      state.priceAndNcc = action.payload;
    },
    // Get
    GetProducts: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { productCode?: string; productName?: string } }>,
    ) => {},
    GetDanhSachBoPhan: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { productCode?: string; productName?: string } }>,
    ) => {},
    GetWareHouse: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetProductUnit: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetDanhSachThietBi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { ma_tscd?: string; ten_tscd?: string; otherFilter?: string } }>,
    ) => {},
    GetMoneyTypeList: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { madvcs: string; ngay_de_nghi_tu_ngay: string; ngay_de_nghi_den_ngay: string; ma_kho: string };
      }>,
    ) => {},
    // [19/10/2024][#20433][ngoc_td] add redux newCustomers, getCustomers
    newCustomers: (state: AccountingInvoiceState, action: PayloadAction<{ data: Customer[]; params: any }>) => {},
    getCustomers: (state: AccountingInvoiceState) => {},
    GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { recId: number };
      }>,
    ) => {},
    GetDanhSachDuyetChi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { CapDuyet: string; madvcs: string; tu_ngay: string; den_ngay: string } }>,
    ) => {},
    GetDanhSachDuyetMuaHang: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { madvcs: string; ngay_de_nghi_tu_ngay: string; ngay_de_nghi_den_ngay: string; ma_kho?: string };
      }>,
    ) => {},
    GetTonKho: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: GetTonKhoDTO; params: any; TonKhoTheoNgay?: boolean }>,
    ) => {},
    getKldinhmuc: (state: AccountingInvoiceState, action: PayloadAction<{ data: GetTonKhoDTO; params: any }>) => {},
    GetGiaVaNhaCungCap: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: GetTonKhoDTO; params: any }>,
    ) => {},
    DuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ data: number[]; params: any }>) => {},
    HuyDuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ data: number[]; params: any }>) => {},
    // Create
    CreatePhieuNhapXuatKho: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: PhieuNhapXuatKhoDTO; params: any }>,
    ) => {},
    ConfirmProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: PhieuDeNghiMuaHangDTO; params: any }>,
    ) => {},
    CreateProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: PhieuDeNghiMuaHangDTO; params: any }>,
    ) => {},
    // Delete
    DeletePhieuNhapXuatKho: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ ids: number[]; params: any }>,
    ) => {},
    DeleteProposalForm: (state: AccountingInvoiceState, action: PayloadAction<{ ids: string[]; params: any }>) => {},
    DeletePhieuDeNghiMuaHang: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ ids: string[]; params: any }>,
    ) => {},
    // Update
    UpdateProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ id: number; data: ProposalFormDTO; params: any }>,
    ) => {},
    // Query
    setQuery_danhSachDuyetChi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { CapDuyet: string; madvcs: string; tu_ngay: string; den_ngay: string } | undefined;
      }>,
    ) => {
      state.query_danhSachDuyetChi = action.payload;
    },
    setQuery_danhSachDuyetMuaHang: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { madvcs: string; ngay_de_nghi_tu_ngay: string; ngay_de_nghi_den_ngay: string } | undefined;
      }>,
    ) => {
      state.query_danhSachDuyetMuaHang = action.payload;
    },
    setCapDuyet: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {
      state.cap_Duyet = action.payload;
    },
    setDateRange: (state: AccountingInvoiceState, action: PayloadAction<any>) => {
      console.log("setDateRange", action.payload);
      state.dateRanges = action.payload;
    },
    setDateTransfers: (state: AccountingInvoiceState, action: PayloadAction<any>) => {
      console.log("setDateTransfers", action.payload);
      state.dateTransfers = action.payload;
    },
    setStatusRequest: (state: AccountingInvoiceState, action: PayloadAction<any>) => {
      state.statusRequest = action.payload;
    },
    getBaoCaoXuatNhapTon: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { data: IBaoCaoXuatNhapTonDTO } }>,
    ) => {},
    setBaoCaoXuatNhapTon: (state: AccountingInvoiceState, action: any) => {
      state.baoCaoXuatNhapTon = action.payload;
    },
    getAdditionalCosts: (state, action: PayloadAction<{ projectId: number, companyId: number }>) => {},
    setAdditionalCosts: (state, action) => {
      state.AdditionalCosts = action.payload;
    },
    getImageAdditionalCosts: (state, action) => {},
    /**
     * update 1 truong 'hinhanh' cho AdditionalCosts (DataType)
     * @param state 
     * @param action 
     */
    updateImageAdditionalCosts: (state, action) => {
      let hasChange = false;
      if (action.payload) {
        const {imageUrl, id} = action.payload;
        const additionalCosts = [...state.AdditionalCosts];
        additionalCosts.forEach(x => {
          if( x.id === id && x.hinhanh !== imageUrl) {
            x.hinhanh = imageUrl;
            hasChange = true;
          }
        });
        if (hasChange) {
          state.AdditionalCosts = additionalCosts;
        }
      }
    },
    /**
     * them/xoa image trong attachmentLinks
     * @param state 
     * @param action 
     */
    updateAttachementImageUrl: (state, action) => {
      let hasChange = false;
      if (action.payload) {
        const {id, isdelete} = action.payload;
        // isdelete: true = xoa, false: them
        const additionalCosts = [...state.AdditionalCosts];
        additionalCosts.forEach((x: DataType) => {
          if( x.id === id) {
            if (isdelete) {
              const drawingIds = action.payload.drawingIds;
              x.attachmentLinks = x.attachmentLinks.filter(xy => !drawingIds.includes(xy.drawingId));
              hasChange = true;
            } else {
              const attachmentLinks = action.payload.attachmentLinks;
              x.attachmentLinks = [...x.attachmentLinks, ...attachmentLinks];
            }
          }
        })
        if (hasChange) {
          state.AdditionalCosts = additionalCosts;
        }
      }
    },

    setSataAttachmentLinks: (state, action) => {
      state.dataAttachmentLinks = action.payload;
    },
    getImageUrlAttachmentLinks: (state, action) => {},
    
    /**
     * update imageUrl cho AttachmentLinks
     * @param state 
     * @param action 
     */
    updateImageUrlAttachmentLinks: (state, action) => { // one attachment
      let isPush = false;
      const attachs = [];
      const attachmentLinks = [...state.dataAttachmentLinks];
      attachmentLinks.forEach(x => {
        if (x.drawingId === action.payload.drawingId) {
          x.imageUrl = action.payload.imageUrl; // update 1 field nay thoi
          isPush = true;
        } 
        attachs.push(x);
      });
      if (!isPush) attachs.push(action.payload);
      state.dataAttachmentLinks = attachs;
    },
    deleteAttachmentLinks: (state, action) => {},
    uploadAttachmentLinks: (state, action) => {},
    splitDeNghiMuaHangTheoNhaCungCap: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { guid: string } }>,
    ) => {},
    CreateAdditionalCost: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ files?: FormData; dataCreate: CostDataCreate[], companyId: number }>,
    ) => {},

    createAdditionalCostSuccess: (state, action: PayloadAction<any>) => {
      state.createAdditionalCostDataLast.dataCreateLast = action.payload; // trong này bao gồm id và datacreate được lưu lại vào dataCreateLast
    },

    UpdateAdditionalCost: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ id: number; dataCreate: CostDataCreate, companyId: number }>,
    ) => {},
    DeleteAdditionalCostRequest: (state: AccountingInvoiceState, action: PayloadAction<{ id: number, projectId: any, companyId: any }>) => {},

    getGiaXuatGanNhat: (state, action) => {console.log(action.payload)},
    setGiaXuatGanNhat: (state, action) => { state.GiaXuatGanNhat = action.payload},
    createFileCPPS: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ itemId: number; dataImage: FormData, projectId: any, companyId: any }>,
    ) => {},
    createFileCPPSSuccess: (state: AccountingInvoiceState, action: PayloadAction<any>) => {},
    emoveFileOfIssue: (state, action) => {},
    deleteFileCPPSRequest: (state, action) => {},
    deleteFileCPPSSuccess: (state: AccountingInvoiceState, action: PayloadAction<any>) => {},
    getBaoCaoDanhThuChiPhi: (state, action) => {},
    setBaoCaoDanhThuChiPhi: (state, action) => {
      state.baoCaoDanhThuChiPhi = action.payload;
    },
    getPhieuNhapKhoTuDeNghiMuaHang: (state, action) => {},
    setPhieuNhapKhoTuDeNghiMuaHang: (state, action) => {
      state.danhSachNhapKho = action.payload;
    },
  },
});

export const accountingInvoiceActions = accountingInvoiceSlice.actions;
export const accountingInvoiceReducer = accountingInvoiceSlice.reducer;
