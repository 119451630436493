import { useEffect } from 'react';

import { issueActions } from '../../store/issue/issueSlice';
import OnlyOfficeViewer from '../Components/ViewFile/OnlyofficeViewer';
import { getEnvVars } from '@/environment';
import { ProjectDocumentsHeader } from '@/pages/Components/Document/ProjectDocumentHeader';
import { accountingInvoiceActions, getBaoCaoDanhThuChiPhi } from '@/store/accountingInvoice';
import { getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getFinanceFile } from '@/store/issue';

const { apiUrl } = getEnvVars();

export const AccoutingManagement = ({ AccoutingKey }: any) => {
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const getBaoCaoDanh = useAppSelector(getBaoCaoDanhThuChiPhi());
  const getFileFinance = useAppSelector(getFinanceFile());

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); 
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const currentDate = new Date();
  const endDate = formatDate(currentDate);

  const startDate = new Date(currentDate);
  startDate.setMonth(currentDate.getMonth() - 3);
  const startDateFormatted = formatDate(startDate);

  // [#20755] [hao_lt] call api báo cáo thu doanh thu chi phí with dynamic dates
  useEffect(() => {
    dispatch(accountingInvoiceActions.getBaoCaoDanhThuChiPhi({ tu_ngay: startDateFormatted, den_ngay: endDate }));
  }, [startDateFormatted, endDate]);

  useEffect(() => {
    if (getBaoCaoDanh) {
      dispatch(issueActions.getFinance({ data: getBaoCaoDanh }));
    }
  }, [getBaoCaoDanh, AccoutingKey]);

  return (
    <div style={{ height: '87vh' }}>
      <ProjectDocumentsHeader title={activeMenu?.label} />
      <OnlyOfficeViewer
        fileUrl={`${apiUrl}/Document/downloadFile/${getFileFinance}?companyId=1`}
        fileType={'xlsx'}
        title={activeMenu?.label}
      />
    </div>
  );
};
