import { useEffect, useState } from 'react';

import { RoleEnum } from '@/common/define';
import { getAuthenticated, policyGranted } from '@/store/app';
import { useAppSelector } from '@/store/hooks';

export const fullPermissionsRoles = [RoleEnum.Director, RoleEnum.Deputy_Director];
export const usePermission = (keys?: string[]) => {
  const [permission, setPermission] = useState(true);

  const isGranted = useAppSelector(policyGranted(keys || []));
  const auth = useAppSelector(getAuthenticated());

  useEffect(() => {
    const userRoles = auth?.roles || [];
    if (fullPermissionsRoles.some(role => userRoles.includes(role))) {
      setPermission(true);
      return;
    }

    setPermission(isGranted);
  }, [isGranted, auth]);

  if (!keys?.length) {
    return true;
  }

  return permission;
};
