import { useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Row, Col, Space, Typography, Modal, Form, Input, Radio, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { accountingInvoiceActions } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { projectActions } from '@/store/project';

interface ActiveMenu {
  title: string;
  pass?: number;
  initialSearch?: string | any;
  onSearchChange?: (search: string) => void;
}

export const ProjectDocumentsHeader = ({ title, pass, initialSearch = '', onSearchChange }: ActiveMenu) => {
  const { t } = useTranslation('document');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const company = useAppSelector(getCurrentCompany());
  const [form] = Form.useForm();
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [searchStr, setSearchStr] = useState();
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    setSearchStr(initialSearch);
  }, [initialSearch]);

  useEffect(() => {
    dispatch(projectActions.getProjectsByCompanyIdRequest(company.id));
  }, [company]);

  const projectList = useAppSelector(state => state.project.projectList);

  const handleModalTheoDoiDongTien = () => {
    form.setFieldsValue({
      fromDate: dayjs().subtract(3, 'month'),
      toDate: dayjs(),
    });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const values = form.getFieldsValue();
    const tuNgay = values.fromDate;
    const denNgay = values.toDate;

    dispatch(
      accountingInvoiceActions.getBaoCaoDanhThuChiPhi({
        tu_ngay: dayjs(tuNgay).format('YYYY-MM-DD'),
        den_ngay: dayjs(denNgay).format('YYYY-MM-DD'),
      }),
    );

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearchChange = (evt: any) => {
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeOutId = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(search);
      }
    }, 500);
    setTimer(timeOutId);
  };

  return (
    <>
      {pass ? (
        <Row style={{ padding: 10, height:60,backgroundColor: 'white' }}>
          <Space style={{ flex: 1 }}>
            <Typography.Title style={{ margin: 0 }} level={4}>
              {title}
            </Typography.Title>
          </Space>
          <Space style={{ marginRight: '115px' }}>
            <Input
              value={searchStr}
              onChange={handleSearchChange}
              allowClear
              placeholder={t('Search')}
              suffix={searchStr ? null : <SearchOutlined />}
              style={{ width: 250 }}
            />
          </Space>
        </Row>
      ) : (
        <Row style={{ padding: 10, backgroundColor: 'white' }}>
          <Space style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Title style={{ margin: 0 }} level={4}>
              {title}
            </Typography.Title>
            <Button type="primary" onClick={handleModalTheoDoiDongTien} style={{ marginTop: 10 }}>
              Tra cứu
            </Button>
          </Space>
        </Row>
      )}

      <Modal
        title="Theo dõi dòng tiền"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        cancelText="Hủy"
        okText="Ok"
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Mã đơn vị" name="unitCode">
            <Input placeholder="Nhập mã đơn vị" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Từ ngày" name="fromDate">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Đến ngày" name="toDate">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Công trình" name="projectCode">
            <Select
              placeholder="Chọn mã công trình"
              value={selectedProject}
              onChange={value => {
                setSelectedProject(value);
                form.setFieldsValue({ projectCode: value });
              }}
            >
              {projectList?.map(project => (
                <Select.Option key={project.code || '0'} value={project.name}>
                  {project.code || '0'} / {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Mã vụ việc" name="caseCode">
            <Input placeholder="Nhập mã vụ việc" />
          </Form.Item>

          <Form.Item label="Mã khách hàng" name="customerCode">
            <Input placeholder="Nhập mã khách hàng" />
          </Form.Item>

          <Form.Item label="Lựa chọn dữ liệu" name="dataOption">
            <Radio.Group>
              <Radio value="release">Số liệu lấy trên các chứng từ đã được duyệt (Release)</Radio>
              <Radio value="unrelease">Số liệu lấy trên các chứng từ chưa được duyệt (Unrelease)</Radio>
              <Radio value="all">Số liệu lấy trên tất cả các chứng từ đã hoặc chưa được duyệt (All)</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
