import bidding from './bidding.json';
import category from './category.json';
import checkin from './checkin.json';
import common from './common.json';
import companyGroup from './companyGroup.json';
import depot from './depot.json';
import document from './document.json';
import employee from './employee.json';
import gantt from './gantt.json';
import kpiSalary from './kpiSalary.json';
import layout from './layout.json';
import login from './login.json';
import material from './material.json';
import news from './news.json';
import organization from './organization.json';
import projects from './projects.json';
import publics from './publics.json';
import shift from './shift.json';
import statistic from './statistic.json';
import status from './status.json';
import subcontractor from './subcontractor.json';
import table from './table.json';
import team from './team.json';
import timeKeeping from './timeKeeping.json';
import weather from './weather.json';
import weeklyAssignment from './weeklyAssignment.json';
import welcome from './welcome.json';

// eslint-disable-next-line
export default {
  bidding,
  common,
  welcome,
  login,
  layout,
  projects,
  checkin,
  shift,
  team,
  employee,
  material,
  organization,
  publics,
  gantt,
  weeklyAssignment,
  status,
  category,
  timeKeeping,
  kpiSalary,
  depot,
  document,
  table,
  statistic,
  companyGroup,
  news,
  subcontractor,
  weather
};
