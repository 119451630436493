import * as React from 'react';

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import "@/common/featureTextRich.css";
import "@/common/ckcontent.css";
import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

// import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import { AuthProvider, ReduxStoreProvider } from './components';
import { AppRouter } from './routes';
import ThemeCustomization from './themes';
import './translations';

function App() {
  return (
    <ReduxStoreProvider>
      <ThemeCustomization>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </ThemeCustomization>
    </ReduxStoreProvider>
  );
}

export default App;
